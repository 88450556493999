import { get } from "./HttpRequest.js";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { errorMessage } from "../services/apiRequest.js";
import defaultUserIp from "../constants/defaultUserIp.js";

const mixKeySalt = (saltString, signVal, saltPos) => {
  if (saltPos.length !== signVal.length) {
    throw Error("Fail to generate USign");
  }

  let mixKey = saltString;

  for (let i = 0; i < saltPos.length; i++) {
    mixKey = mixKey.substring(0, saltPos[i] * 1) + signVal.charAt(i);
    if (saltString.length - 1 >= saltPos[i] * 1 + 1) {
      mixKey += saltString.substring(saltPos[i] * 1 + 1);
    }
  }
  return mixKey;
};

export const getUserIP = (cb) => {
  //const abortController = new AbortController();
  /* const timeout = parseInt(process.env.REACT_APP_API_TIMEOUT); */
  const timeout = parseInt(3000);

  const request0 = new Promise((resolve, reject) => {
    let timeoutHandle;

    const timeoutPromise = new Promise((resolve) => {
      timeoutHandle = setTimeout(() => {
        resolve(defaultUserIp);
      }, timeout);
    });

    const actualRequest = new Promise((resolve, reject) => {
      get("/api/ip", null, null, null, timeout, (err, res) => {
        clearTimeout(timeoutHandle);
        if (err) {
          reject(err);
        } else {
          resolve(res);
        }
      });
    });

    Promise.race([timeoutPromise, actualRequest]).then(resolve).catch(reject);
  });

  const request1 = new Promise((resolve, reject) => {
    let timeoutHandle;

    const timeoutPromise = new Promise((resolve) => {
      timeoutHandle = setTimeout(() => {
        resolve(defaultUserIp);
      }, timeout);
    });

    const actualRequest = new Promise((resolve, reject) => {
      get(
        "https://api64.ipify.org/?format=json",
        null,
        null,
        null,
        timeout,
        (err, res) => {
          clearTimeout(timeoutHandle);
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        }
      );
    });
    Promise.race([timeoutPromise, actualRequest]).then(resolve).catch(reject);
  });

  const request2 = new Promise((resolve, reject) => {
    let timeoutHandle;

    const timeoutPromise = new Promise((resolve) => {
      timeoutHandle = setTimeout(() => {
        resolve(defaultUserIp);
      }, timeout);
    });

    const actualRequest = new Promise((resolve, reject) => {
      get(
        "https://api.ipify.org?format=json",
        null,
        null,
        null,
        timeout,
        (err, res) => {
          clearTimeout(timeoutHandle);
          if (err) {
            reject(err);
          } else {
            resolve(res);
          }
        }
      );
    });
    Promise.race([timeoutPromise, actualRequest]).then(resolve).catch(reject);
  });

  Promise.any([request0, request1, request2])
    .then((result) => {
      cb(null, result);
    })
    .catch((err) => {
      cb(err);
    });
};

export const genSign = (uSign) => {
  return new Promise((resolve, reject) => {
    const secretKey = process.env.REACT_APP_SECRET;
    const secretSalt = process.env.REACT_APP_SECRET_SALT;
    const saltPosStr = process.env.REACT_APP_SALTPOS;
    const saltPos = saltPosStr.split("+");
    const chars =
      "0123456789abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ!@#$%^&*()-=_+[]{}|;:,.<>?";
    let saltString = "";
    for (let k = 0; k < 64; k++) {
      const randomC = Math.floor(Math.random() * chars.length);
      saltString += chars.charAt(randomC);
    }
    if (uSign === "") {
      /*backup_link: "https://geolocation-db.com/json/"*/

      getUserIP((error, response) => {
        if (error) {
          errorMessage(
            "zh-TW",
            { message: "All ip source down" },
            new AbortController(),
            (err, res) => {}
          );
        }
        let t = defaultUserIp["ip"].split(",");
        if (response) t = response["ip"].split(".");
        let singVal = [];
        if (t.length === 4) {
          for (let i = 0; i < 4; i++) {
            let partList = [];
            const partLength = t[secretSalt[i] * 1 - 1].length;
            for (let j = partLength; j > 0; j--) {
              const n = parseInt(t[secretSalt[i] * 1 - 1][j - 1]);
              partList.push(secretKey[n]);
            }
            for (let k = 2 - partList.length; k >= 0; k--) {
              partList.push(secretKey[k]);
            }
            singVal.push.apply(singVal, partList);
          }
        }
        singVal = singVal.toString().replaceAll(",", "");
        singVal = mixKeySalt(saltString, singVal, saltPos);
        resolve(singVal);
      });
    } else {
      resolve(uSign);
    }
  });
};

export const getBrowserFingerPrint = async (bFingerPrint) => {
  if (bFingerPrint === "") {
    const fp = await FingerprintJS.load();
    const result = await fp.get();
    const visitorId = result.visitorId;
    return visitorId;
  } else {
    return bFingerPrint;
  }
};
