import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import "../styles/MoreInfoPage.scss";
import HxgHeader from "../components/HxgHeader.js";

const MoreInfoPage = (props) => {
  const navigate = useNavigate();
  const { question, info, setInfo } = useContext(ChatContext);

  useEffect(() => {
    if (!question) {
      navigate("/");
    }
  }, [question, navigate]);

  const enterOnClick = () => {
    navigate("/selecthxgpage");
  };

  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/info.png`}
          className="MoreInfoPage__infoImg"
          alt=""
        />
        <textarea
          value={info}
          onChange={(e) => setInfo(e.target.value)}
          className="MoreInfoPage__setInfo"
        />
        <div className="MoreInfoPage-enter" onClick={enterOnClick}>
          <div className="MoreInfoPage-enter__text">▶</div>
        </div>
      </HxgHeader>
    </>
  );
};

export default MoreInfoPage;
