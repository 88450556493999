import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import "../styles/OnlineHxgPage.scss";
import HxgHeader from "../components/HxgHeader.js";

const OnlineHxgPage = (props) => {
  const navigate = useNavigate();
  const { tossResult, setTossResult } = useContext(ChatContext);
  const [imgs, setImgs] = useState([
    ...Array(3).fill(`${process.env.PUBLIC_URL}/imgs/coin0c.png`),
    ...Array(3).fill(`${process.env.PUBLIC_URL}/imgs/coin0b.png`),
  ]);
  const [totalToss, setTotalToss] = useState(0);

  useEffect(() => {
    setTossResult("");
  }, [setTossResult]);

  const updateImageAtIndex = (index, newValue) => {
    setImgs((prevImgs) => {
      const newImgs = [...prevImgs];
      newImgs[index] = newValue;
      return newImgs;
    });
  };

  const buttonOnClick = () => {
    let cointossResult = "";
    for (let i = 0; i < 3; i++) {
      const toss = Math.floor(Math.random() * 2);

      cointossResult = `${cointossResult}${toss}`;
    }
    if (totalToss >= 0 && totalToss <= 5) {
      switch (cointossResult) {
        case "111":
          updateImageAtIndex(
            totalToss,
            totalToss >= 0 && totalToss <= 2
              ? `${process.env.PUBLIC_URL}/imgs/coin8d.png`
              : `${process.env.PUBLIC_URL}/imgs/coin8h.png`
          );
          setTossResult(tossResult + "9");
          break;
        case "011":
          updateImageAtIndex(
            totalToss,
            totalToss >= 0 && totalToss <= 2
              ? `${process.env.PUBLIC_URL}/imgs/coin8a.png`
              : `${process.env.PUBLIC_URL}/imgs/coin8e.png`
          );
          setTossResult(tossResult + "8");
          break;
        case "101":
          updateImageAtIndex(
            totalToss,
            totalToss >= 0 && totalToss <= 2
              ? `${process.env.PUBLIC_URL}/imgs/coin8a.png`
              : `${process.env.PUBLIC_URL}/imgs/coin8e.png`
          );
          setTossResult(tossResult + "8");
          break;
        case "110":
          updateImageAtIndex(
            totalToss,
            totalToss >= 0 && totalToss <= 2
              ? `${process.env.PUBLIC_URL}/imgs/coin8a.png`
              : `${process.env.PUBLIC_URL}/imgs/coin8e.png`
          );
          setTossResult(tossResult + "8");
          break;
        case "100":
          updateImageAtIndex(
            totalToss,
            totalToss >= 0 && totalToss <= 2
              ? `${process.env.PUBLIC_URL}/imgs/coin8b.png`
              : `${process.env.PUBLIC_URL}/imgs/coin8f.png`
          );
          setTossResult(tossResult + "7");
          break;
        case "010":
          updateImageAtIndex(
            totalToss,
            totalToss >= 0 && totalToss <= 2
              ? `${process.env.PUBLIC_URL}/imgs/coin8b.png`
              : `${process.env.PUBLIC_URL}/imgs/coin8f.png`
          );
          setTossResult(tossResult + "7");
          break;
        case "001":
          updateImageAtIndex(
            totalToss,
            totalToss >= 0 && totalToss <= 2
              ? `${process.env.PUBLIC_URL}/imgs/coin8b.png`
              : `${process.env.PUBLIC_URL}/imgs/coin8f.png`
          );
          setTossResult(tossResult + "7");
          break;
        case "000":
          updateImageAtIndex(
            totalToss,
            totalToss >= 0 && totalToss <= 2
              ? `${process.env.PUBLIC_URL}/imgs/coin8c.png`
              : `${process.env.PUBLIC_URL}/imgs/coin8g.png`
          );
          setTossResult(tossResult + "6");
          break;
        default:
          break;
      }
      setTotalToss(totalToss + 1);
    }

    if (totalToss === 5) {
      setTimeout(() => {
        navigate("/tossresultpage"); // 3秒後導航到 /target-page
      }, 3000);
    }
  };
  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin0a.png`}
          className="OnlineHxgPage__coin0aImg"
          alt=""
        />
        <img src={imgs[5]} className="OnlineHxgPage__coin0bImg" alt="" />
        <img src={imgs[4]} className="OnlineHxgPage__coin0bImg" alt="" />
        <img src={imgs[3]} className="OnlineHxgPage__coin0bImg" alt="" />
        <div className="OnlineHxgPage-button" onClick={buttonOnClick}>
          <img
            src={`${process.env.PUBLIC_URL}/imgs/coin2.png`}
            className="OnlineHxgPage-button__coin2Img"
            alt=""
          />
        </div>
        <img src={imgs[2]} className="OnlineHxgPage__coin0cImg" alt="" />
        <img src={imgs[1]} className="OnlineHxgPage__coin0cImg" alt="" />
        <img src={imgs[0]} className="OnlineHxgPage__coin0cImg" alt="" />
      </HxgHeader>
    </>
  );
};

export default OnlineHxgPage;
