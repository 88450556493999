import React, { useEffect, useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import "../styles/hxgPage.scss";
import HxgHeader from "../components/HxgHeader.js";

const HxgPage = (props) => {
  const navigate = useNavigate();
  const { tossResult, setTossResult } = useContext(ChatContext);
  const [count, setCount] = useState(5);
  const [hxgResult, setHxgResult] = useState(Array(6).fill("gray"));

  useEffect(() => {
    setTossResult("");
  }, [setTossResult]);

  const updateHxgResultAtIndex = (index, newValue) => {
    setHxgResult((e) => {
      const newHxgResult = [...e];
      newHxgResult[index] = newValue;
      return newHxgResult;
    });
  };
  const buttonOnClick = (type) => {
    console.log(count);
    if (count >= 0 && count <= 5) {
      switch (type) {
        case 0:
          updateHxgResultAtIndex(count, "yang");
          setTossResult(tossResult + "9");
          break;
        case 1:
          updateHxgResultAtIndex(count, "lessYang");
          setTossResult(tossResult + "7");
          break;
        case 2:
          updateHxgResultAtIndex(count, "yin");
          setTossResult(tossResult + "6");
          break;
        case 3:
          updateHxgResultAtIndex(count, "lessYin");
          setTossResult(tossResult + "8");
          break;
        default:
          break;
      }
      setCount(count - 1);
    }
    if (count === 0) {
      setTimeout(() => {
        navigate("/tossresultpage"); // 3秒後導航到 /target-page
      }, 3000);
    }
  };
  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/coin0a.png`}
          className="HxgPage__coin0aImg"
          alt=""
        />

        {hxgResult.map((result, index) => (
          <div className="HxgPage-hxg" key={index}>
            <div
              className={`HxgPage-hxg__gray ${
                result === "gray" ? "" : "hidden"
              }`}
            />
            <div
              className={`HxgPage-hxg__yin ${
                result === "yin" || result === "lessYin" ? "" : "hidden"
              }`}
            />
            <div
              className={`HxgPage-hxg__yang ${
                result === "yang" || result === "lessYang" ? "" : "hidden"
              }`}
            />
            <div
              className={`HxgPage-hxg__grayText ${
                result === "gray" ? "" : "hidden"
              }`}
            >
              none
            </div>

            <img
              src={`${process.env.PUBLIC_URL}/imgs/coin9.png`}
              className={`HxgPage-hxg__yinText ${
                result === "yin" ? "" : "hidden"
              }`}
              alt=""
            />

            <img
              src={`${process.env.PUBLIC_URL}/imgs/coin8.png`}
              className={`HxgPage-hxg__yangText ${
                result === "yang" ? "" : "hidden"
              }`}
              alt=""
            />

            <img
              src={`${process.env.PUBLIC_URL}/imgs/coin10.png`}
              className={`HxgPage-hxg__lessYangText ${
                result === "lessYang" ? "" : "hidden"
              }`}
              alt=""
            />

            <img
              src={`${process.env.PUBLIC_URL}/imgs/coin11.png`}
              className={`HxgPage-hxg__lessYinText ${
                result === "lessYin" ? "" : "hidden"
              }`}
              alt=""
            />
          </div>
        ))}

        <img
          src={`${process.env.PUBLIC_URL}/imgs/hxgTitle.png`}
          className="HxgPage__hxgTitle"
          alt=""
        />

        <div className="HxgPage__hxgButtonsSet">
          <div className="HxgPage__hxgButtons">
            <img
              src={`${process.env.PUBLIC_URL}/imgs/hxg3words.png`}
              className="HxgPage__hxg3words"
              alt=""
              onClick={() => buttonOnClick(0)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/hxg2heads1word.png`}
              className="HxgPage__hxg2heads1word"
              alt=""
              onClick={() => buttonOnClick(1)}
            />
          </div>
          <div className="HxgPage__hxgButtons">
            <img
              src={`${process.env.PUBLIC_URL}/imgs/hxg3heads.png`}
              className="HxgPage__hxg3heads"
              alt=""
              onClick={() => buttonOnClick(2)}
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/hxg2words1head.png`}
              className="HxgPage__hxg2words1head"
              alt=""
              onClick={() => buttonOnClick(3)}
            />
          </div>
        </div>
      </HxgHeader>
    </>
  );
};

export default HxgPage;
