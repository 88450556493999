import React, { createContext, useState } from "react";

const ChatContext = createContext();

const ChatProvider = ({ children }) => {
  const [question, setQuestion] = useState("");
  const [info, setInfo] = useState("");
  const [tossResult, setTossResult] = useState("");

  return (
    <ChatContext.Provider
      value={{
        question,
        setQuestion,
        info,
        setInfo,
        tossResult,
        setTossResult,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export { ChatProvider, ChatContext };
