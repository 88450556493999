import React from "react";
import "../styles/HxgHeader.scss";

const HxgHeader = ({ children, popup }) => {
  return (
    <>
      <div className="HxgHeader_BG">
        {popup}
        <div className="HxgHeader">
          <div className="HxgHeader-content">
            <img
              src={`${process.env.PUBLIC_URL}/imgs/logoN.png`}
              className="HxgHeader-content__logoN"
              alt=""
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/bgMoon.png`}
              className="HxgHeader-content__bgMoon"
              alt=""
            />
            <img
              src={`${process.env.PUBLIC_URL}/imgs/bgEarth.png`}
              className="HxgHeader-content__bgEarth"
              alt=""
            />
            {children}
          </div>
        </div>
      </div>
    </>
  );
};

export default HxgHeader;
