import React, { useState, useContext } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import "../styles/QuestionPage.scss";
import HxgHeader from "../components/HxgHeader.js";

const QuestionPage = (props) => {
  const navigate = useNavigate();
  const { question, setQuestion } = useContext(ChatContext);

  const enterOnClick = () => {
    if (question) navigate("/moreinfopage");
  };

  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/askQuestion.png`}
          className="QuestionPage__askQuestionImg"
          alt=""
        />
        <input
          value={question}
          onChange={(e) => setQuestion(e.target.value)}
          className="QuestionPage__setQuestion"
        />
        <div className="QuestionPage-enter">
          <div className="QuestionPage-enter__circle" onClick={enterOnClick}>
            <div className="QuestionPage-enter__text">▶</div>
          </div>
        </div>
      </HxgHeader>
    </>
  );
};

export default QuestionPage;
