import React, { useState, useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { ChatContext } from "../contexts/ChatContext.js";
import { SessionContext } from "../contexts/sessionContext.js";
import { useIntl } from "react-intl";
import {
  sendHxg,
  createPayment,
  showSessionContent,
  getSession,
  webCreditCount,
} from "../services/apiRequest.js";
import "../styles/DivinationPage.scss";
import CheckoutForm from "../components/CheckoutForm.js";
import HxgHeader from "../components/HxgHeader.js";
import userLog from "../utils/userLog.js";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";

const DivinationPage = (props) => {
  const { LANG } = props;
  const navigate = useNavigate();
  const intl = useIntl();
  const dollar = "USD$2";
  const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
  const { question, tossResult, info } = useContext(ChatContext);
  const { userSign, browserFingerPrint } = useContext(SessionContext);
  const [showText, setShowText] = useState("");
  const [ans, setAns] = useState();
  const [aiAskMe, setAiAskMe] = useState();
  const [padding, setPadding] = useState(false);
  const [aiAskMeAns, setAiAskMeAns] = useState("");
  const [remainChangeQuestion, setremainChangeQuestion] = useState(0);
  const [showAskAiPopUp, setShowAskAiPopUp] = useState(false);
  const [askAi, setAskAi] = useState("");
  const [remainInteractive, setRemainInteractive] = useState(false);
  const [isShortAns, setIsShortAns] = useState(false);
  const [isShowPay, setIsShowPay] = useState(false);
  const [clientSecret, setClientSecret] = useState("");
  const [remindCreditsCount, setRemindCreditsCount] = useState("");
  let chatRecords = useRef(null);

  const abortController = useRef(new AbortController());
  const langMap = { en: "en", "zh-TW": "ch" };
  let webSessionId = useRef(null);
  const questionType = "other";

  const filterPostData = {
    question,
    language: langMap[LANG],
    questionType,
    agentId: browserFingerPrint,
  };

  const updateCreditDisplay = () => {
    webCreditCount(
      langMap[LANG],
      { agentId: browserFingerPrint, append: true },
      abortController.current,
      async (error, resQ) => {
        if (error) {
          navigate("/");
        } else {
          if (resQ) {
            const remainCredits = resQ.credit.total;
            const remainFreeCredits = resQ.credit.freeCredits;
            setRemindCreditsCount(remainFreeCredits);
            if (remainCredits > 0) {
              if (remainFreeCredits <= 0) {
                setRemindCreditsCount(remainCredits);
              } else {
                // handleUseCredit();
              }
            } else {
              // handleUseCredit();
            }
          }
        }
      }
    );
  };

  const payOnClick = (e) => {
    const postData = { sessionId: webSessionId.current };
    createPayment(
      langMap[LANG],
      postData,
      abortController.current,
      (err, res) => {
        if (err) {
          navigate("/");
        } else {
          setClientSecret(res.client_secret);
          setIsShowPay(true);
        }
      }
    );
    //for testing
    // handleSuccessPay();
  };

  const handleSuccessPay = () => {
    const postData = { sessionId: webSessionId.current };
    setPadding(true);
    showSessionContent(
      langMap[LANG],
      postData,
      abortController.current,
      (err, res) => {
        setPadding(false);
        if (err) {
          navigate("/");
        } else {
          setIsShortAns(res.shortAns);
          setRemainInteractive(res.remainInteractiveTime);
          setAns(res.messages.at(-1).content);
          setShowText(res.messages.at(-1).content);
        }
      }
    );
  };

  const getSessionId = (cb) => {
    getSession(langMap[LANG], abortController.current, (err, res) => {
      if (err) {
        navigate("/");
      } else {
        webSessionId.current = res;
        cb();
      }
    });
  };

  const startProcess = () => {
    updateCreditDisplay();
    sendHxg(
      langMap[LANG],
      filterPostData,
      abortController.current,
      (err, res) => {
        if (err) {
          navigate("/");
        } else {
          if (res.passed) {
            handleDivination(res);
          } else {
            navigate("/");
          }
        }
      }
    );
  };
  const askAiEnterOnClick = () => {
    setShowAskAiPopUp(false);
    setPadding(true);
    setShowText("");
    const postData = {
      agentId: browserFingerPrint,
      isotime: new Date().valueOf(),
      language: langMap[LANG],
      question: askAi,
      questionType,
      sessionId: webSessionId.current,
      uHash: userSign,
      userInterQuestion: 1,
      userhexagram: tossResult,
    };
    sendHxg(
      langMap[LANG],
      postData,
      abortController.current,
      async (err, res) => {
        setPadding(false);
        if (err) {
          console.log(err);
        } else {
          try {
            if (res) {
              setRemainInteractive(res.remainInteractive);
              setAns(res.aiAnswer);
              setShowText(res.aiAnswer);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );
  };
  const askAiOnClick = () => {
    setShowAskAiPopUp(true);
  };

  const aiAskMeOnClick = () => {
    setPadding(true);
    const postData = {
      userhexagram: tossResult,
      question: question,
      language: langMap[LANG],
      isotime: new Date().valueOf(),
      uHash: userSign,
      sessionId: webSessionId.current,
      agentId: browserFingerPrint,
      questionType,
      aiInterQuestion: 1,
    };
    sendHxg(
      langMap[LANG],
      postData,
      abortController.current,
      async (err, res) => {
        setPadding(false);
        if (err) {
          console.log(err);
        } else {
          try {
            if (res) {
              setRemainInteractive(res.remainInteractive);
              setremainChangeQuestion(res.remainChangeQuestion);
              setAiAskMeAns("");
              setAiAskMe(res.aiQuestion);
              setShowText(res.aiQuestion);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );

    //   setTimeout(() => {
    //     setPadding(false);
    //     setAiAskMe("aiaskme aiaskme......");
    //     setShowText("aiaskme aiaskme......");
    //   }, 2000);
  };

  const aiAskMeAnsOnClick = () => {
    const postData = {
      agentId: browserFingerPrint,
      language: langMap[LANG],
      question: aiAskMeAns,
      questionType,
      sessionId: webSessionId.current,
      userAnsQ: 1,
      userInterQuestion: 1,
      userhexagram: tossResult,
    };
    setPadding(true);
    sendHxg(
      langMap[LANG],
      postData,
      abortController.current,
      async (err, res) => {
        setPadding(false);
        if (err) {
          console.log(err);
        } else {
          try {
            if (res) {
              setRemainInteractive(res.remainInteractive);
              setremainChangeQuestion(0);
              setAiAskMe("");
              setAns(res.aiAnswer);
              setShowText(res.aiAnswer);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );
  };

  const changQuestionOnClick = () => {
    if (remainChangeQuestion > 0) {
      const postData = {
        agentId: browserFingerPrint,
        aiInterQuestion: 1,
        changeQuestion: 1,
        isotime: new Date().valueOf(),
        language: langMap[LANG],
        question: "",
        questionType,
        sessionId: webSessionId.current,
        uHash: userSign,
        userhexagram: tossResult,
      };
      setPadding(true);
      sendHxg(
        langMap[LANG],
        postData,
        abortController.current,
        async (err, res) => {
          setPadding(false);
          if (err) {
            console.log(err);
          } else {
            try {
              if (res) {
                setremainChangeQuestion(res.remainChangeQuestion);
                setAiAskMe(res.aiQuestion);
                setShowText(res.aiQuestion);
              }
            } catch (e) {
              console.log(e);
            }
          }
        }
      );
    }
  };

  useEffect(() => {
    if (ans) return;
    getSessionId(() => {
      userLog(
        "enter_chat",
        userSign,
        LANG,
        webSessionId.current,
        (err, res) => {
          startProcess();
          // setAns("");
          // setTimeout(() => {
          //   setAns("ansansans.....");
          //   setShowText("ansansans.....");
          // }, 2000);
        }
      );
    });
  }, []);

  const handleDivination = (filterRes) => {
    const locPicPath = LANG === "en" ? "iching_div_jp" : "iching_div_ch";
    const postData = {
      classifyResult: filterRes,
      userhexagram: tossResult,
      question: question,
      language: langMap[LANG],
      isotime: new Date().valueOf(),
      uHash: userSign,
      sessionId: webSessionId.current,
      agentId: browserFingerPrint,
      questionType,
    };
    if (info) postData.extraInfo = info;
    sendHxg(
      langMap[LANG],
      postData,
      abortController.current,
      async (err, res) => {
        if (err) {
          console.log(err);
        } else {
          try {
            if (res) {
              updateCreditDisplay();
              setIsShortAns(res.shortAns);
              setRemainInteractive(res.remainInteractive);
              setAns(res?.answer?.choices[0]?.message?.content);
              setShowText(res?.answer?.choices[0]?.message?.content);
            }
          } catch (e) {
            console.log(e);
          }
        }
      }
    );
  };

  return (
    <>
      <HxgHeader>
        <img
          src={`${process.env.PUBLIC_URL}/imgs/div02.png`}
          className="DivinationPage__titleImg"
          alt=""
        />
        <div className="DivinationPage-coin">
          <img
            alt=""
            src={`${process.env.PUBLIC_URL}/imgs/coin.png`}
            className="DivinationPage-coin__coinImg"
          />
          {`${remindCreditsCount}${intl.formatMessage({
            id: "ChatProcess.preDay",
          })}`}
        </div>

        <div className="DivinationPage-textBox">
          <div>{showText}</div>
          {remainChangeQuestion !== 0 && (
            <div
              className="DivinationPage-textBox__changQuestionButton"
              onClick={changQuestionOnClick}
            >
              {intl.formatMessage({ id: "ChatProcess.changeQuestion" })}
            </div>
          )}
        </div>
        <div className="DivinationPage-infoBox">
          {!ans && intl.formatMessage({ id: "ChatProcess.firstHxg" })}
          {ans && !!isShortAns && (
            <div>
              {intl.formatMessage(
                { id: "ChatProcess.unlockHints" },
                { dollar }
              )}
              <div
                className="DivinationPage-infoBox__fullLineButton"
                onClick={payOnClick}
              >
                {intl.formatMessage({ id: "ChatProcess.payToUnlock" })}
              </div>
            </div>
          )}
          {!padding && ans && !aiAskMe && remainInteractive > 0 && (
            <div>
              {ans &&
                intl.formatMessage({ id: "ChatProcess.interactiveTimes" })}
              {ans && (
                <div className="DivinationPage-infoBox-buttonSet">
                  <div
                    className="DivinationPage-infoBox-buttonSet__button"
                    onClick={aiAskMeOnClick}
                  >
                    {intl.formatMessage({ id: "ChatProcess.masterAskMe" })}
                  </div>
                  <div
                    className="DivinationPage-infoBox-buttonSet__button"
                    onClick={askAiOnClick}
                  >
                    {intl.formatMessage({ id: "ChatProcess.youAskAi" })}
                  </div>
                  <div className="DivinationPage-infoBox-buttonSet__button">
                    {intl.formatMessage({ id: "ChatProcess.line" })}
                  </div>
                </div>
              )}
            </div>
          )}
          {!padding && ans && aiAskMe && (
            <div className="DivinationPage-infoBox-aiAskMeAnsInput">
              <textarea
                value={aiAskMeAns}
                onChange={(e) => setAiAskMeAns(e.target.value)}
                className="DivinationPage-infoBox-aiAskMeAnsInput__textarea"
                placeholder={intl.formatMessage({
                  id: "ChatProcess.ansPlease",
                })}
              />
              <div
                className="DivinationPage-infoBox-aiAskMeAnsInput-enter"
                onClick={aiAskMeAnsOnClick}
              >
                <div className="DivinationPage-infoBox-aiAskMeAnsInput-enter__text">
                  ▶
                </div>
              </div>
            </div>
          )}
          {!padding && remainInteractive === 0 && (
            <div>
              {intl.formatMessage({ id: "ChatProcess.endText" })}
              <div className="DivinationPage-infoBox__fullLineButton">
                {intl.formatMessage({ id: "ChatProcess.line" })}
              </div>
            </div>
          )}
          {padding && (
            <div>{intl.formatMessage({ id: "ChatProcess.pleaseWait" })}</div>
          )}
        </div>
        {showAskAiPopUp && (
          <div className="DivinationPage-askAiPopUp">
            <div className="DivinationPage-askAiPopUp-content">
              <textarea
                value={askAi}
                onChange={(e) => setAskAi(e.target.value)}
                className="DivinationPage-askAiPopUp-content__textarea"
                placeholder={intl.formatMessage({
                  id: "ChatProcess.questionPlease",
                })}
              />
              <div
                className="DivinationPage-askAiPopUp-content-enter"
                onClick={askAiEnterOnClick}
              >
                <div className="DivinationPage-askAiPopUp-content-enter__text">
                  ▶
                </div>
              </div>
            </div>
          </div>
        )}
        {isShowPay && (
          <Elements
            options={{
              clientSecret,
              appearance: {
                theme: "stripe",
              },
            }}
            stripe={stripePromise}
          >
            <CheckoutForm
              clientSecret={clientSecret}
              changeShowPay={() => {
                setIsShowPay(false);
              }}
              handleSuccessPay={handleSuccessPay}
            />
          </Elements>
        )}
      </HxgHeader>
    </>
  );
};

export default DivinationPage;
